import React, { useState } from "react";
import axios from "axios";
import { API_URI } from "../../config";
import { InputForm } from "../contactForm/input/index";

import BackImage from "./assets/callback.png";

import {
  ContactContainer,
  TextContainer,
  ImageContainer,
  Picture,
  Title,
  Line,
  Button,
  Form,
  TextContent,
  BottomSection,
  FormMessage,
  CheckboxWrapper,
  CheckboxText,
  CheckboxInput,
  ErrorMessage,
  FormRadio,
  FormRadioLabel,
  FormInputLabel,
  RadioContainer,
  RadioButtonsContainer,
  CheckboxInputLong,
} from "./styles";
import { CheckboxWrapperLong, ModalDescription } from "../contactForm/styles";
import ModalForm from "../ModalForm";

const ContactFormVolunteer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [inputs, setInputs] = useState({ receiveEmail: false });
  const [succesForm, setSuccesForm] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [showError, setError] = useState(false);

  const handleChange = (event) => {
    setSuccesForm(false);
    setErrorForm(false);
    const name = event.target.name;
    const value = event.target.value;
    if (event.target.type === "radio") {
      setInputs((values) => ({ ...values, experience: value }));
    } else {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.receiveEmail) {
      setError(false);
      try {
        await axios.post(
          API_URI + "/api/hospice/contactMailVolunteer/",
          inputs
        );
        setSuccesForm(true);
        setInputs({ receiveEmail: true });
      } catch (error) {
        setErrorForm(true);
      }
    } else {
      setError(true);
      e.preventDefault();
    }
  };

  const changeSetCheck = () => {
    setInputs((values) => ({ ...values, receiveEmail: !inputs.receiveEmail }));
  };

  const handlePrivacyPolicyChange = () => {
    setError(false);
    setInputs((values) => ({
      ...values,
      privacyPolicy: !inputs.privacyPolicy,
    }));
  };

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <ContactContainer id="callBack">
      <TextContainer>
        <TextContent>
          <Title>GET IN TOUCH</Title>
          <Line />
          <Form onSubmit={handleSubmit}>
            <InputForm
              id="name"
              type="text"
              name="name"
              placeholder="Your Name"
              errorMsg="Invalid name"
              required={true}
              value={inputs.name || ""}
              onChange={handleChange}
            />
            <InputForm
              id="state"
              type="state"
              name="state"
              placeholder="City/State"
              errorMsg="Invalid City/State"
              required={true}
              value={inputs.state || ""}
              onChange={handleChange}
            />
            <RadioContainer>
              <FormInputLabel>
                Any previous experience in hospice care?
              </FormInputLabel>
              <RadioButtonsContainer>
                <FormRadioLabel>
                  <FormRadio
                    type="radio"
                    value="Yes"
                    name="Yes"
                    checked={inputs.experience === "Yes"}
                    onClick={handleChange}
                  />
                  Yes
                </FormRadioLabel>

                <FormRadioLabel>
                  <FormRadio
                    type="radio"
                    value="No"
                    name="No"
                    checked={inputs.experience === "No"}
                    onClick={handleChange}
                  />
                  No
                </FormRadioLabel>
              </RadioButtonsContainer>
            </RadioContainer>
            <InputForm
              id="email"
              type="email"
              name="email"
              placeholder="E-mail address"
              errorMsg="Invalid email"
              required={true}
              value={inputs.email || ""}
              onChange={handleChange}
            />
            <InputForm
              id="phone"
              type="phone"
              name="phone"
              placeholder="Phone Number"
              errorMsg="Invalid phone"
              required={true}
              value={inputs.phone || ""}
              onChange={handleChange}
            />
            <CheckboxWrapper>
              <CheckboxInput
                type="checkbox"
                checked={inputs.receiveEmail}
                onChange={changeSetCheck}
              />
              <CheckboxText>
                I consent to receive information from Life Hospice
              </CheckboxText>
            </CheckboxWrapper>
            <CheckboxWrapperLong>
              <CheckboxInputLong
                type="checkbox"
                onChange={handlePrivacyPolicyChange}
              />
              <CheckboxText>
                I have read and agree to Life Hospice{" "}
                <span
                  onClick={handleModalToggle}
                  style={{
                    color: "white",
                    zIndex: 150,
                    cursor: "pointer",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  SMS privacy policy
                </span>
              </CheckboxText>
            </CheckboxWrapperLong>
            {showError && (
              <ErrorMessage>
                Please consent to receive information before sending request
              </ErrorMessage>
            )}
            <BottomSection>
              <Button type="submit"> Send Request </Button>
              {succesForm ? (
                <FormMessage>Information sent successfully</FormMessage>
              ) : (
                errorForm && (
                  <FormMessage>Error. Please try again later</FormMessage>
                )
              )}
            </BottomSection>
          </Form>
        </TextContent>
      </TextContainer>
      <ModalForm isOpen={isModalOpen} onClose={handleModalToggle}>
        <ModalDescription>
          <span style={{ fontWeight: "bold" }}>Privacy Notice: </span>
          When you opt-in to receive SMS messages from us, we want to assure you
          that we respect your privacy. We do not share or sell your phone
          number or any personal information associated with your opt-in for the
          purposes of SMS or marketing purposes. Your trust is important to us,
          and we use your information solely for the purpose of sending you
          relevant SMS messages as requested. If you have any questions or
          concerns, feel free to contact us at{" "}
          <span style={{ color: "#0000ff", textDecoration: "underline" }}>
            hello@hospicecare.life
          </span>
        </ModalDescription>
      </ModalForm>
      <ImageContainer>
        <Picture>
          <img
            src={BackImage}
            alt="Volunteer and elderly patient knitting together"
          />
        </Picture>
      </ImageContainer>
    </ContactContainer>
  );
};

export default ContactFormVolunteer;
