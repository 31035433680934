import React, { useEffect } from "react";
import AOS from "aos";

import { Helmet, HelmetProvider } from "react-helmet-async";

import Layout from "../components/layout";
import Seoinfo from "../components/seoinfo/seoinfo";
import VolunteersParagraph from "../components/volunteers";
import VolunteerRequirement from "../components/volunteerRequirements";
import VolunteerCoordinator from "../components/volunteersCoordinator";
import ContactFormVolunteer from "../components/contactFormVolunteer";
import seoImage from "../../static/seo/seo_image.png";
import Hero1024 from "../images/home-care/Hero-volunteers.png";
import HeroMobile from "../images/home-care/hero_mobile.png";
import {
  HeroContainer,
  InternalWrapper,
  MobileDiv,
  HeroTextDiv,
  Title,
  Paragraph,
  PhoneAndParagraph,
  HeroPicture,
} from "../styles/pages/volunteers.jsx";

const Home = () => {
  useEffect(
    () => {
      AOS.init({
        //initialise with other settings
        duration: 2000,
        once: true,
      });
    },
    [],
    AOS
  );

  return (
    <HelmetProvider>
      <Layout>
        <Helmet>
          <link rel="canonical" href="https://hospicecare.life/volunteers/" />
        </Helmet>
        <Seoinfo
          siteName={"Volunteers | Life Hospice"}
          titlePage={"Volunteers | Life Hospice"}
          description={
            "Vital Support for Patients & Families by Compassionate Life Hospice Team. Administrative, Companionship, Supplementary Services Available"
          }
          keywords={
            "Compassionate hospice volunteers, Enriching hospice care roles, Impactful volunteer opportunities"
          }
          type={"website"}
          url={"/volunteers/"}
          imageFacebook={seoImage}
        />
        <HeroContainer
          bgImage={Hero1024}
          alt="Hospice life volunteer playing cards with elderly lady"
        >
          <HeroPicture>
            <source srcSet={Hero1024} type="image/avif" />
            <img
              src={Hero1024}
              alt="Hospice life volunteer playing cards with elderly lady"
            />
          </HeroPicture>
          <InternalWrapper>
            <MobileDiv
              bgImage={HeroMobile}
              alt="Hospice life volunteer playing cards with elderly lady"
            />
            <HeroTextDiv>
              <Title>JOIN OUR LIFE HOSPICE TEAM!</Title>
              <PhoneAndParagraph>
                <Paragraph>Hospice Volunteers Needed!</Paragraph>
              </PhoneAndParagraph>
            </HeroTextDiv>
          </InternalWrapper>
        </HeroContainer>
        <VolunteersParagraph />
        <VolunteerRequirement />
        <VolunteerCoordinator />
        <ContactFormVolunteer />
      </Layout>
    </HelmetProvider>
  );
};

export default Home;
