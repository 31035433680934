import React from "react";
import ImageOffice from "./assets/3.png";

import {
  ContactContainer,
  TextContainer,
  ImageContainer,
  Title,
  Line,
  Text,
  TextContent,
  OfficeImage,
} from "./styles";

const VolunteerRequirement = () => {
  return (
    <ContactContainer>
      <TextContainer>
        <TextContent>
          <Title>VOLUNTEER REQUIREMENTS</Title>
          <Line />
          <Text>
            Life Hospice’s onboarding process begins with each prospective
            volunteer filling out an application, where we ask for information
            regarding employment and any past volunteer experiences, as well as
            references and other personal information that will help Life
            Hospice determine if a volunteer is a good fit for our organization.
          </Text>
          <Text>
            Next, volunteers will interview with our Volunteer Coordinator and
            Director of Clinical Services at our Livingston office. From there,
            we ask that each applicant provides two references that we may
            contact. Each applicant must also undergo a criminal background
            check and obtain medical clearance. Before beginning, each volunteer
            will complete an orientation at our Livingston office, to learn more
            about hospice and a volunteer’s role.
          </Text>
        </TextContent>
      </TextContainer>
      <ImageContainer>
        <OfficeImage
          src={ImageOffice}
          alt="Two patients happily playing chess"
        />
      </ImageContainer>
    </ContactContainer>
  );
};

export default VolunteerRequirement;
