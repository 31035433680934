import React from "react";
import Image from "./assets/image.png";

import {
  ContactContainer,
  ContactContainerMobile,
  TextContainer,
  ImageContainer,
  Title,
  Line,
  Text,
  TextContent,
  OfficeImage,
  BoldText,
} from "./styles";

const VolunteerCoordinator = () => {
  return (
    <>
      <ContactContainer>
        <ImageContainer>
          <OfficeImage
            src={Image}
            alt="Nicole Beeney is Life Hospice’s Volunteer Coordinator"
          />
        </ImageContainer>
        <TextContainer>
          <TextContent>
            <Title>VOLUNTEER COORDINATOR</Title>
            <Line />
            <Text>
              <BoldText>Nicole Beeney</BoldText> is Life Hospice’s Volunteer
              Coordinator, who works to develop our volunteer program to best
              support our patients and their families.
            </Text>
            <Text>
              Nicole graduated from Rutgers University with her degree in
              psychology, and has many years of experience volunteering in the
              community in various capacities. You may contact Nicole directly
              at <BoldText>nicole.beeney@hospicecare.life</BoldText> or{" "}
              <BoldText>973-500-2532</BoldText> for any additional information
              or with any questions regarding volunteering with Life Hospice.
            </Text>
          </TextContent>
        </TextContainer>
      </ContactContainer>
      <ContactContainerMobile>
        <TextContainer>
          <TextContent>
            <Title>VOLUNTEER COORDINATOR</Title>
            <Line />
            <Text>
              <BoldText>Nicole Beeney</BoldText> is Life Hospice’s Volunteer
              Coordinator, who works to develop our volunteer program to best
              support our patients and their families.
            </Text>
            <Text>
              Nicole graduated from Rutgers University with her degree in
              psychology, and has many years of experience volunteering in the
              community in various capacities. You may contact Nicole directly
              at <BoldText>nicole.beeney@hospicecare.life</BoldText> or{" "}
              <BoldText>973-500-2532</BoldText> for any additional information
              or with any questions regarding volunteering with Life Hospice.
            </Text>
          </TextContent>
        </TextContainer>
        <ImageContainer>
          <OfficeImage
            src={Image}
            alt="Nicole Beeney is Life Hospice’s Volunteer Coordinator"
          />
        </ImageContainer>
      </ContactContainerMobile>
    </>
  );
};

export default VolunteerCoordinator;
